




































































































































































































.body1StyleSPan {
	color: #ff4b04;
}

.body1StylepFont {
	// margin-top: 0.3rem;
	font-size: 0.28rem;
	// padding-top: .5rem;
}

/* @import "../../assets/css/puliceStyle.css"; */
.body1StyleInputStyle {
	border: 0px;
	width: 60%;
}

.bodyStyleDivStyleP {
	margin-top: 0.3rem;
	margin-bottom: 0.3rem;
}

.bodyStyleDivStyle {
	border-bottom: 1px solid #fbfbfd;
	padding-bottom: 0.3rem;
}

.body1Style {
	margin-top: 0.2rem;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
	padding: 0.3rem 0.4rem;
	font-family: PingFangSC-Regular;
	min-height:91.8vh;
}

.bottom1 {
	margin-top: 2rem;
}

.whole {
	background: #ffffff;
}
// ----------20200411[start]------
.layoutBox {
	// height: calc(100vh - 0.88rem);
	height: 100vh;
	// padding: 0.1rem 0.3rem;
}

.button {
	border: none !important;
	font-size: 0.24rem;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;